<template>
    <div class="IntanMax">
        <canvas id="myCanvas"></canvas>
        <!-- 上面 -->
        <div class="IntanAbove">
            <div class="Back">
                <img @click="$router.push('/')" src="../../assets/CulturalHomePage/PomePage.png" alt="" style="height: 100%; cursor: pointer;" />
            </div>
            <div class="User">
                <img @click="IntanPerson()" src="../../assets/CulturalHomePage/personal.png" alt="" style="height: 100%; cursor: pointer;" />
            </div>
            <!-- 导航栏 -->
            <div class="IntanNavigation">

                <!-- 首页导航 -->
                <!-- </div> -->
                <!-- 标题 -->
                <div class="IntanHeadline">
                    <span style="margin-top: 2vh">非遗展览馆</span>
                </div>
                <!-- 个人导航 -->
            </div>
            <div class="IntansSideshow">
                <div class="swiper-container swiper2">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide to" v-for="(item, index) in category" :key="index">
                            <div class="IntanContent" @click="getExtraList( item.categoryId)">
                                <!-- 名称 -->
                                <div class="IntanInfan">
                                    <span>{{ item.categoryName }}</span>
                                </div>
                                <!-- 图片 -->
                                <div class="IntanPicture">
                                    <img :src="item.logoUrl" alt="" style="width: 100%; height: 100%" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="IntanButtonOne">
                    <div class="swiper-button-prev swiper-button-prev_1" @click="SwiperButtonPrev()"></div>
                    <div class="swiper-button-next swiper-button-next_1" @click="SwiperButtonNext()"></div>
                </div>
            </div>
        </div>
        <!-- 下面 -->
        <div class="IntanBelow">
            <!-- 分类介绍 -->
            <div class="IntanClassify">
                <!-- 左边 -->
                <div class="IntanLeft">
                    <img src="../../assets/IntangibleCultural/left.png" alt="" style="width: 100%; height: 100%" />
                </div>
                <!-- 右边 -->
                <div class="IntanRight">
                    <img src="../../assets/IntangibleCultural/right.png" alt="" style="width: 100%; height: 100%" />
                </div>
                <!-- 人物分类内容 -->
                <div class="IntangContent">
                    <div class="Entry" v-for="(item,index) in ExtraList" :key="index">
                        <div class="Extra" :style="ExtraIdx==index?'display:none':'display:block'" @click="changIdx(index)">{{ item.title }}</div>
                        <div class="ImgBox" style="width: 200px;height: 200px;" @click="goDetail(item,index)">
                            <img :src="item.logoUrl" style="width: 100%;height: 100%;">
                            <div>{{ item.title }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Detail v-if="detailShow" class="Detail" :detail="detail"></Detail>
    </div>
</template>

<script>
import BackTop from '../../assets/BackTop/BackTop.vue'
import $ from 'jquery'
import Detail from './Detail.vue'

export default {
    components: { BackTop, Detail },
    provide () {
        return {
            reloadAll: this.reloadAll,
        }
    },
    data () {
        return {
            // bj: require('../../assets/IntangibleCultural/collect/BgOne.png'),
            // BjOne: require('../../assets/IntangibleCultural/collect/BgTwo.png'),
            category: [], // 非遗类别列表
            defaultInitialSlide: 0,
            ExtraList: [],//非遗详情列表
            detailShow: false,
            ExtraIdx: 0

        }
    },

    created () {
        this.IntainitSwiperOne()
    },
    updated () { },
    mounted () {
        this.GetIntangibleList()
        this.XingKong()
    },

    methods: {
        IntainitSwiperOne () {
            setTimeout(() => {
                var swiper = new Swiper('.swiper2', {
                    effect: 'coverflow',
                    grabCursor: true,
                    centeredSlides: true,
                    slidesPerView: 'auto',
                    initialSlide: 6,
                    paginationClickable: true,
                    loopedSlides: 3,
                    slideToClickedSlide: true,
                    observer: true, //修改swiper自己或子元素时，自动初始化swiper 解决vue下划不动的问题
                    observeParents: true, //修改swiper的父元素时，自动初始化swiper
                    // 前，后进按钮
                    prevButton: '.swiper-button-prev_1',
                    nextButton: '.swiper-button-next_1',
                    pagination: {
                        el: ' .swiper-pagination .swiper2',
                    },
                    on: {
                        // 切换时 过渡结束时触发
                        transitionEnd: (swiperHere) => {
                            var idx = swiperHere.activeIndex
                            var id = this.category[idx].categoryId
                            this.getExtraList(id)

                        },
                    },

                    coverflowEffect: {
                        rotate: -10,
                        stretch: -10,
                        depth: 100,
                        modifier: 2,
                        slideShadows: false,
                    },
                })
            }, 300)
        },
        SwiperButtonPrev () {
        },
        SwiperButtonNext () {
        },
        //星空特效
        XingKong () {
            var myCanvas = document.getElementById('myCanvas')
            var ctx = myCanvas.getContext('2d')
            function init () {
                // 设置canvas区域的范围为整个页面
                myCanvas.width = window.innerWidth
                myCanvas.height = window.innerHeight
            }
            init()
            // 监听屏幕大小改变 重新为canvas大小赋值
            window.onresize = init

            var list = []
            // 将页面上每一个小圆点的信息存在list数组中
            // 数组中的每一位是一个对象  对象中存着每一个小点的信息
            // 利用构造函数生成数组

            function Star (x, y, radius, disX, disY) {
                // 将传入的参数设置为对象的属性
                // 位置坐标
                this.x = x
                this.y = y
                // 半径
                this.radius = radius
                // 变化距离
                this.disX = disX
                this.disY = disY
            }

            for (var i = 0; i < 800; i++) {
                // 设置位随机值
                var x = Math.random() * myCanvas.width
                var y = Math.random() * myCanvas.height
                var radius = Math.random() * 2
                var disX = x - myCanvas.width / 2
                var disY = y - myCanvas.height / 2
                // 每一个的信息存在对象中  所有小点存在list数组中
                list.push(new Star(x, y, radius, disX, disY))
            }

            // 绘制并且运动函数
            function animate () {
                // 在每次渲染前清除一下屏幕
                ctx.clearRect(0, 0, myCanvas.width, myCanvas.height)
                // 下一次渲染  根据存放在数组中的信息进行画小星星
                for (var i = 0; i < 800; i++) {
                    // 取出每一个信息点信息
                    var a = list[i]

                    // a的坐标在改变  根据disx disy进行改变  /50改变值设置的小一点
                    a.x += a.disX / 50
                    a.y += a.disY / 50

                    // 如果小圆点运动到边界之外
                    if (
                        a.x < 0 ||
                        a.y < 0 ||
                        a.x > myCanvas.width ||
                        a.y > myCanvas.height
                    ) {
                        // 重新出现一个小星星的位置
                        a.x = Math.random() * myCanvas.width
                        a.y = Math.random() * myCanvas.height
                        // 同时根据 当前新出现的位置设置移动距离
                        a.disX = a.x - myCanvas.width / 2
                        a.disY = a.y - myCanvas.height / 2
                    }

                    // 开始画每一个点
                    ctx.beginPath()
                    // 设置填充颜色
                    ctx.fillStyle = '#00F5FF'
                    // 每一小星星为一个小圆
                    ctx.arc(a.x, a.y, a.radius, 0, Math.PI * 2, false)
                    // 进行填充
                    ctx.fill()
                }
                // 不断绘制canvas  根据disx与disy的位置变化  更新坐标  视觉上位运动状态
                setTimeout(animate, 40)
            }
            animate()
        },
        // 非遗类别
        GetIntangibleList () {
            var axios = require('axios')
            var config = {
                method: 'get',
                url: this.$Schttp + '/vtp/app/ihInfo/getIhCategoryList',
                headers: {
                    Authorization: 'Bearer ' + this.$cookies.get('token'),
                    'Content-Type': 'application/json',
                },
            }
            axios(config).then((res) => {
                if (res.data.code == 200) {
                    this.category = res.data.data
                    this.defaultInitialSlide = Math.floor(this.category.length / 2)
                    // this.categoryId = this.category[0].categoryId
                }
            })
        },
        // 点击非遗名称
        changIdx (idx) {
            this.ExtraIdx = idx
            $('.Entry:eq(' + this.ExtraIdx + ')').css('display', 'none')
            $('.ImgBox').css('display', 'none')
            $('.ImgBox:eq(' + this.ExtraIdx + ')').fadeIn(500)
            $('.Entry:eq(' + this.ExtraIdx + ')').css('display', 'block')
        },
        // 获取非遗列表
        getExtraList (id) {
            this.ExtraIdx = 0
            $('.Entry').css('display', 'none')
            $('.ImgBox').css('display', 'none')
            var axios = require('axios')
            var config = {
                method: 'get',
                url: this.$Schttp + '/vtp/app/ihInfo/ihInfoIndex/' + id,
                headers: { Authorization: 'Bearer ' + this.$cookies.get('token'), 'Content-Type': 'application/json' },
            }
            axios(config).then((res) => {
                if (res.data.code == 200) {
                    this.ExtraList = res.data.data
                }

                setTimeout(() => {
                    $('.Entry').css('display', 'block')
                    $('.ImgBox:eq(' + this.ExtraIdx + ')').fadeIn(1000)
                    $('.Entry').addClass('move')
                }, 500)
            })
        },
        // 点击跳转首页
        IntanHome () {
            this.$router.push('/')
        },
        // 点击跳转个人中心
        IntanPerson () {
            // 进入页面就判断是否有token  如果没有那就继续登陆 如果有就进入个人中心
            if (this.$cookies.get('token') == null) {
                this.$router.push('/login')
            } else {
                this.$router.push('/personalCenter')
            }
        },
        //点击视频全屏播放
        HandleFullScreenOne (e) {
            this.launchFullscreen(e.target)
        },
        HandleExitFullScreenOne (e) {
            this.exitFullscreen(e.target)
        },
        exitFullscreen (element) {
            if (document.exitFullscreen) {
                document.exitFullscreen()
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen()
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen()
            } else if (document.oRequestFullscreen) {
                document.oCancelFullScreen()
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen()
            } else {
                var docHtml = document.documentElement
                var docBody = document.body
                var videobox = element
                docHtml.style.cssText = ''
                docBody.style.cssText = ''
                videobox.style.cssText = ''
                document.IsFullScreen = false
            }
        },
        launchFullscreen (element) {
            //此方法不可以在異步任務中執行，否則火狐無法全屏
            if (element.requestFullscreen) {
                element.requestFullscreen()
            } else if (element.mozRequestFullScreen) {
                element.mozRequestFullScreen()
            } else if (element.msRequestFullscreen) {
                element.msRequestFullscreen()
            } else if (element.oRequestFullscreen) {
                element.oRequestFullscreen()
            } else if (element.webkitRequestFullscreen) {
                element.webkitRequestFullScreen()
            } else {
                var docHtml = document.documentElement
                var docBody = document.body
                var videobox = element
                var cssText = 'width:100%;height:100%;overflow:hidden;'
                docHtml.style.cssText = cssText
                docBody.style.cssText = cssText
                videobox.style.cssText =
                    cssText + ';' + 'margin:0px;padding:0px;'
                document.IsFullScreen = true
            }
        },
        // 退出全屏暂停播放
        handleFullscreenChange () {
            const video = this.$refs.myVideo
            if (
                !document.fullscreenElement &&
                !document.mozFullScreenElement &&
                !document.webkitFullscreenElement
            ) {
                video.pause() // 当视频退出全屏时暂停播放
            }
        },
        goDetail (data, idx) {
            this.detail = {
                title: data.title,
                summary: data.summary,
                logoUrl: data.logoUrl,
                heritageId: data.heritageId
            }
            this.detailShow = true
            setTimeout(() => {
                $('.Detail').fadeIn(500)
            }, 100)

        },
    },
}
</script>

<style lang="less" scoped>
* {
    margin: 0;
    padding: 0;
}

.IntanMax {
    position: absolute;
    width: 100%;
    height: 100%;
    min-width: 1024px;
    background: radial-gradient(
        200% 100% at bottom center,
        #013990,
        #6dadfb,
        #1b2947
    );
    background: radial-gradient(
        200% 105% at top center,
        #013990 10%,
        #6dadfb 40%,
        #013990 65%,
        #1b2947
    );
    display: flex;
    align-items: center;
    flex-direction: column;
    /deep/ .swiper-button-prev::after {
        content: '' !important;
    }
    /deep/ .swiper-button-next::after {
        content: '' !important;
    }
    #myCanvas {
        width: 100%;
        height: 100%;
        position: absolute;
    }
    .IntanAbove {
        width: 100%;
        max-height: 46%;
        display: flex;
        align-items: center;
        flex-direction: column;
        .Back {
            position: absolute;
            width: 7.5vh;
            height: 7vh;
            left: 10%;
            top: 3vh;
            cursor: pointer;
            z-index: 999;
        }
        .User {
            position: absolute;
            width: 7.5vh;
            height: 7vh;
            right: 10%;
            top: 3vh;
            cursor: pointer;
            z-index: 999;
        }
        .IntanNavigation {
            width: 80%;
            height: 9vh;
            display: flex;
            justify-content: space-between;
            position: relative;
            .IntanHeadline {
                width: 86%;
                margin: 0 auto;
                height: 100%;
                background-image: url(../../assets/IntangibleCultural/topbg.png);
                background-size: 100% 100%;
                color: #ffffff;
                font-size: 3vh;
                display: flex;
                justify-content: center;
            }
        }

        .IntansSideshow {
            width: 65%;
            height: 65%;
            margin-top: 3vh;
            overflow: hidden;
            .swiper-container {
                z-index: 0;
                width: 100%;
                height: 100%;

                .swiper-wrapper {
                    width: 100%;
                    .swiper-slide {
                        background-position: center;
                        width: 30%;
                        // background: url(../../assets/IntangibleCultural/box.png);
                        background-size: 100% 100%;
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        .IntanContent {
                            width: 100%;
                            height: 100%;
                            // margin-top: 2vh;
                            padding-top: 2vh;
                            background: url(../../assets/IntangibleCultural/collect/BgOne.png);
                            background-size: 100% 100%;

                            .IntanInfan {
                                color: #ffffff;
                                font-size: 2vh;
                                margin-top: 2vh;
                                // margin-left: 3vh;
                                text-align: center;
                            }

                            .IntanPicture {
                                width: 70%;
                                height: 62%;
                                margin: 0 auto;
                            }
                            // }

                            .blue {
                                background: url(../../assets/IntangibleCultural/collect/BgTwo.png);
                                background-size: 100% 100%;
                            }
                        }

                        .IntanBottom {
                            width: 60%;
                            height: 5%;
                        }
                    }
                }
            }

            .IntanButtonOne {
                .swiper-button-next_1 {
                    /*先将原始箭头的颜色设置为透明的  然后设置一个背景图片  达到修改按钮样式的目的*/
                    width: 2%;
                    height: 28%;
                    right: 5.2%;
                    top: 14%;
                    left: auto;
                    color: rgba(0, 0, 0, 0) !important;
                    background: url('../../assets/IntangibleCultural/IntaRight.png')
                        center center no-repeat;
                    background-size: 100% 100%;
                }

                .swiper-button-prev_1 {
                    width: 2%;
                    height: 28%;
                    left: 5.2%;
                    top: 14%;
                    right: auto;
                    color: rgba(0, 0, 0, 0) !important;
                    background: url('../../assets/IntangibleCultural/IntaLeft.png')
                        center center no-repeat;
                    background-size: 100% 100%;
                }
            }
        }
    }

    .IntanBelow {
        width: 100%;
        height: 54%;
        background-color: #083d76;
        overflow: hidden;
        .IntanClassify {
            position: relative;
            width: 100%;
            height: 100%;

            .IntanLeft {
                position: absolute;
                left: 1%;
                top: 2%;
                width: 4%;
                height: 15%;
            }

            .IntanRight {
                position: absolute;
                right: 1%;
                top: 2%;
                width: 4%;
                height: 15%;
            }

            .IntangContent {
                width: 100%;
                box-sizing: border-box;
                height: 100%;
                min-height: 14rem;
                display: flex;
                justify-content: center;

                .Entry {
                    color: #fff;
                    position: relative;
                    cursor: pointer;
                    margin: 0 2rem;
                    z-index: 10;
                    // animation: bounce 1s ease-in-out 1s;
                    transform-origin: center bottom;
                    display: none;
                    .Extra {
                        position: relative;
                        top: 30%;
                        padding: 25px 0 30px;
                        font-size: 16px;
                        line-height: 1.375;
                        white-space: nowrap;
                        background-size: 100% 100%;
                        display: inline-block;
                        background-color: #083d76;
                        // animation: bounce 1s ease-in-out 1s;
                        writing-mode: vertical-lr;
                    }
                    .ImgBox {
                        display: none;
                        text-align: center;
                        background-color: #083d76;
                        position: relative;
                        top: 20%;
                        // animation: bounce 1s ease-in-out 1s;
                        img {
                            border-radius: 50%;
                        }
                        div {
                            position: absolute;
                            bottom: 90px;
                            left: 0;
                            right: 0;
                            background: rgba(0, 0, 0, 0.5);
                            height: 20px;
                        }
                    }
                    .Extra:before {
                        top: 0;
                        content: '';
                        position: absolute;
                        left: 0;
                        width: 22px;
                        height: 22px;
                        background: url('../../assets/IntangibleCultural/left.png')
                            no-repeat;
                        background-size: 100% 100%;
                        transform: rotate(45deg);
                    }
                    .Extra:after {
                        content: '';
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        width: 22px;
                        height: 22px;
                        background: url('../../assets/IntangibleCultural/right.png')
                            no-repeat;
                        background-size: 100% 100%;
                        transform: rotate(135deg);
                    }
                }
                .Entry:before {
                    content: '';
                    position: absolute;
                    right: 50%;
                    top: 0;
                    bottom: 0;
                    border-left: 1px solid #ccc;
                }
                .move {
                    animation: mymove 2s;
                }

                @keyframes mymove {
                    from {
                        height: 0px;
                    }
                    to {
                        height: 100%;
                    }
                }

                @keyframes bounce {
                    0%,
                    100% {
                        transform: translate(0, 0);
                    }
                    50% {
                        transform: translate(0, -20px);
                        animation-timing-function: cubic-bezier(
                            0.5,
                            1.6,
                            0.4,
                            0.8
                        );
                    }
                }
            }
        }
    }
}
</style>