<template>
      <div class="BackMax" @click="DjFan()" v-if="isFixed">  
          <img src="../../assets/Login/BottomTop.png" alt="" style="width:100%; height:100%;">
      </div>
</template>

<script >
 export default{
      data() {
        return {
            isFixed: false,
        }
      },
      created(){
       
      },
      mounted() {
         window.addEventListener('scroll', this.handleScroll)
      },
      methods:{
         // 滚动
         handleScroll() {
               let scrollTop =
                  window.pageYOffset ||
                  document.documentElement.scrollTop ||
                  document.body.scrollTop // 滚动条偏移量

               // let offsetTop = document.querySelector('#boxFixed').offsetTop // 要滚动到顶部吸附的元素的偏移量
               this.isFixed = scrollTop > 500 ? true : false // 如果滚动到了预定位置，this.isFixed就为true，否则为false
         },
         //点击回到顶部
         DjFan(){
            document.body.scrollTop = document.documentElement.scrollTop = 0;
         },
      }
}
</script>

<style lang="less" scoped>
 *{
    padding:0;
    margin: 0;
 }
 .BackMax{
    position: fixed;
    width:70px;
    height:70px;
    top:50%;
    right:0;
    cursor: pointer;
 }
 
</style>